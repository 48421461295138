@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anuphan:wght@400;500;600&family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anuphan:wght@200&display=swap');

.classic-4 {
  font-weight: bold;
  font-family: monospace;
  font-size: 30px;
  clip-path: inset(0 3ch 0 0);
  animation:c4 1s steps(4) infinite;
}
@keyframes c4 {to{clip-path: inset(0 -1ch 0 0)}}


.show {
    /* position: absolute; */
    /* width: 100%;
    height: 100%; */
    /* display: flex;
    justify-content: center;
    align-items: center;
    text-align: center; */
    animation: fade 1.5s ease-in-out;
    /* border-radius: 1rem; */
  }
  
  .show > div {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
  }

  .dot {
    /* cursor: pointer; */
    /* height: 5px;
    width: 20px; */
    /* margin: 0 2px; */
    background-color: #ddd;
    /* display: inline-flex; */
    transition: background-color 0.6s ease;
  }

.dot:hover,
.active {
  /* background-color: #717171; */
  opacity: 1;
}
  
  
  
  /* @-webkit-keyframes trans {
    from {
      left: 100%;
    }
    to {
      left: 0%;
    }
  }
  
  @keyframes trans {
    from {
      left: 100%;
    }
    to {
      left: 0%;
    }
  } */
  
  @keyframes fade {
    from {
      opacity: 0.4;
      border-radius: 1rem;
    }
    to {
      opacity: 1;
      border-radius: 1rem;
    }
  }
